/*
 * @Author: lbh
 * @Date: 2022-01-10 16:04:42
 * @LastEditors: lbh
 * @LastEditTime: 2024-03-06 17:50:12
 * @Description: 默認配置
 */
// 獲取窗口寬度
let win_width = document.documentElement.scrollWidth || 1920;

const config = [
  {
    key: 'swiper',
    name: '首頁-頂部banner',
    img: require('../../assets/do-img/首頁-頂部banner.png'),
    show: true,
    configs: {
      autoplay: false, // 是否自動輪播
      interval: 3, // 時間
      loop: false, // 是否 循環
      items: [
        {
          phoneImg: '',
          bgImg: require('../../assets/material/首頁/Mask-Group@2x.png'),
          bgAlt: '',
          type: '2',
          img: require('../../assets/material/首頁/页面样机@2x.png'),
          alt: '',
          btns: [
            {
              title: '預約演示',
              type: 'default',
            },
            {
              title: '查看詳情',
              type: 'plain',
            },
          ],
          title: 'RicePOS支持不同設備雲同步',
          desc: '移動式多功能餐飲管理系統，搭建優良高效管理系統。 輕鬆管理所有方便快捷點餐服務，減低落錯單機會。 數據可視化，直觀看到數據變化趨勢。',
        },
        {
          type: '1',
          bgImg: require('../../assets/material/首頁/Component 213.png'),
          btns: [
            {
              title: '預約演示',
              type: 'default',
            },
            {
              title: '查看詳情',
              type: 'plain',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'tabs',
    name: '公司簡介',
    img: require('../../assets/do-img/公司簡介.png'),
    alt: '',
    show: true,
    configs: {
      title: '',
      desc: '',
      items: [
        {
          title: '公司介紹',
          configs: {
            title: '薑軟件有限公司',
            desc: 'Gingersoft Limited',
            icon: require('../../assets/material/關於Gingersoft/dingwei-icon.png'),
            img: require('../../assets/material/關於Gingersoft/gongsi-img.png'),
            alt: '',
            address: '中國·香港',
            addressDetail: '香港九龍青山道700號時運中心5樓4室',
            logo: require('../../assets/material/關於Gingersoft/Gingersoft-logo 2.png'),
            logoDesc:
              'Gingersoft薑軟件在2012年於香港成立，多年來一直専注於研究及開發餐飲管理系統，擁有豐富的行業經驗及服務多所小型、大型及連鎖餐廳，致力為每位客戶提供客製化的智能餐飲管理方案，務求為客戶節省人力成本、提高經營效率！我們更擁有自己的研發團隊，能及時有效地因應社會發展需要為每位客戶設計出最合適的產品！',
            manageImage: require('../../assets/material/關於Gingersoft/jingying-img.png'),
            manageTitle: '經營理念',
            manageDesc:
              '清楚知道餐飲業面臨“租金高 人工高 食材成本高 利潤低”的三高一低痛點，因應全球發展趨勢各行各業的戰場亦從線下實體店已經延伸至線上外賣店。為了符合時代發展需要，創新推出雲端餐飲管理系統，結合掃碼落單及會員系統，助每位老闆從經營到營銷到營利！',
          },
        },
        {
          title: '發展歷程',
          configs: {
            items: [
              {
                yyyy: '2022年',
                desc: '現在',
              },
              {
                yyyy: '2021年',
                desc: '雲端餐飲管理系統RicePOS與會計系統 Xero完成技術對接業務拓展至新加坡',
              },
              {
                yyyy: '2020年',
                desc: '與著名物流系統、電子支付系統完成技術對接',
              },
              {
                yyyy: '2027年',
                desc: '全港首創固定碼點餐，建立會員系統 推出飲食著數APP“飯糰”',
              },
              {
                yyyy: '2013年',
                desc: '我們擁有了第一個合作夥伴',
              },
              {
                yyyy: '2012年',
                desc: '香港成立 Gingersoft',
              },
            ],
          },
        },
        {
          title: '合作夥伴',
          configs: {
            src: require('../../assets/do-img/長圖.png'),
            fit: 'contain',
            alt: '',
            go: '',
            phoneSrc: '',
          },
        },
      ],
    },
  },
  {
    key: 'partner',
    name: '合作夥伴',
    img: require('../../assets/do-img/合作夥伴.png'),
    show: true,
    configs: {
      items: [
        {
          img: require('../../assets/material/合作夥伴/01@2x.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/合作夥伴/02@2x.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/合作夥伴/03@2x.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/合作夥伴/04@2x.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/合作夥伴/05@2x.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/合作夥伴/06@2x.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/合作夥伴/07@2x.png'),
          alt: '',
        },
      ],
    },
  },
  {
    key: 'offerData',
    name: '報價-報價方案',
    img: require('../../assets/do-img/報價-報價方案.png'),

    show: true,
    configs: {
      title: '報價方案',
      desc: '經濟實惠的餐飲POS，是你的不二之選',
      attention:
        '*保養指軟件及硬件的遠程指導保養，如涉及到店維修，則需因應情況按次收費。',
      childs: [
        {
          price: '$15,000',
          year: '/年',
          dataArr: [
            {
              title: '1 x 雲端餐飲系統',
              subheading: '雲端餐飲系統',
              datas: [
                { title: '餐牌預設及管理' },
                { title: '管理訂單及數據實時監控' },
                { title: '數據分析及報表' },
                { title: '安裝及保養*（一年）' },
              ],
            },
          ],
          checkData: [
            { title: 'RicePOS' },
            { title: '廚房打印機' },
            { title: '樓面打印機' },
            { title: '收銀錢櫃' },
            { title: '路由器' },
          ],
          btn1: {
            title: '立即預訂',
            go: '',
          },
          btn2: {
            title: '預約演示',
            go: '',
          },
        },
        {
          price: '$21,000',
          year: '/年',
          dataArr: [
            {
              title: '1 x 雲端餐飲系統',
              subheading: '雲端餐飲系統',
              datas: [
                { title: '餐牌預設及管理' },
                { title: '管理訂單及數據實時監控' },
                { title: '數據分析及報表' },
                { title: '安裝及保養*（一年）' },
              ],
            },
            {
              title: '1 x 雲端餐飲系統 1 x QR Code落單系統',
              subheading: '雲端餐飲系統111',
              datas: [
                { title: '支持多種設備點餐(iOS/Android)' },
                { title: '不同時段餐牌設置並自動切換' },
                { title: '連接雲端餐飲系統' },
              ],
            },
          ],
          checkData: [
            { title: 'RicePOS' },
            { title: '廚房打印機' },
            { title: '樓面打印機' },
            { title: '收銀錢櫃' },
            { title: '路由器' },
          ],
          btn1: {
            title: '立即預訂',
            go: '',
          },
          btn2: {
            title: '預約演示',
            go: '',
          },
        },
      ],
    },
  },
  {
    key: 'companyIntroduction',
    name: '公司介紹',
    img: '',
    show: false,
    configs: {
      title: '薑軟件有限公司',
      desc: 'Gingersoft Limited',
      icon: require('../../assets/material/關於Gingersoft/dingwei-icon.png'),
      img: require('../../assets/material/關於Gingersoft/gongsi-img.png'),
      address: '中國·香港',
      alt: '',
      addressDetail: '香港九龍青山道700號時運中心5樓4室',
      logo: require('../../assets/material/關於Gingersoft/Gingersoft-logo 2.png'),
      logoDesc:
        'Gingersoft薑軟件在2012年於香港成立，多年來一直専注於研究及開發餐飲管理系統，擁有豐富的行業經驗及服務多所小型、大型及連鎖餐廳，致力為每位客戶提供客製化的智能餐飲管理方案，務求為客戶節省人力成本、提高經營效率！我們更擁有自己的研發團隊，能及時有效地因應社會發展需要為每位客戶設計出最合適的產品！',
      manageImage: require('../../assets/material/關於Gingersoft/jingying-img.png'),
      manageTitle: '經營理念',
      manageImageAlt: '',
      manageDesc:
        '清楚知道餐飲業面臨“租金高 人工高 食材成本高 利潤低”的三高一低痛點，因應全球發展趨勢各行各業的戰場亦從線下實體店已經延伸至線上外賣店。為了符合時代發展需要，創新推出雲端餐飲管理系統，結合掃碼落單及會員系統，助每位老闆從經營到營銷到營利！',
    },
  },
  {
    key: 'map',
    name: '地圖',
    show: true,
    configs: {
      title: '我們的位置',
      img: 'https://ricepon.oss-cn-hongkong.aliyuncs.com//home/mapChange.png',
      lat: '22.3392',
      lng: '114.1511',
    },
  },
  {
    key: 'company',
    name: '公司信息',
    img: require('../../assets/do-img/公司信息.png'),
    show: true,
    configs: {
      title: '薑軟件有限公司',
      desc: 'Gingersoft Limited',
      centerText: '',
      items: [
        {
          img: require('../../assets/material/聯絡我們/dianhua-icon.png'),
          title: '聯繫電話',
          contact: '852 8200 2022',
        },
        {
          img: require('../../assets/material/聯絡我們/youxiang-icon.png'),
          title: '郵箱地址',
          contact: 'info@gingersoft.com.hk',
        },
        {
          img: require('../../assets/material/聯絡我們/facebook-icon.png'),
          title: '社交平台',
          contact: 'Facebook',
        },
      ],
    },
  },
  {
    key: 'offer',
    name: '報價-產品特點',
    img: require('../../assets/do-img/報價-產品特點.png'),
    show: true,
    configs: {
      title: 'Gingersoft',
      desc: '豐富的產品滿足你全面的業務需求',
      itemData: {
        title: '試用看看就知道，你的店正需要',
        descObj: [
          {
            img: require('../../assets/material/首頁/Vector@2x.png'),
            text: '專人上門諮詢規劃',
          },
          {
            img: require('../../assets/material/首頁/Vector@2x.png'),
            text: '配合現場硬體規劃',
          },
        ],
        icons: [
          {
            img: require('../../assets/material/首頁/Group 41@2x.png'),
            title: '預約演示',
          },
          {
            img: require('../../assets/material/首頁/Group 41@2x(1).png'),
            title: '安裝教學',
          },
          {
            img: require('../../assets/material/首頁/Group 41@2x(2).png'),
            title: '熱線服務',
          },
          {
            img: require('../../assets/material/首頁/Group 41@2x(3).png'),
            title: '故障排解',
          },
          {
            img: require('../../assets/material/首頁/Group 41@2x(4).png'),
            title: '備機支援',
          },
        ],
        btn: {
          title: '立即申請試用',
          go: 'helpMe',
        },
        img: require('../../assets/material/首頁/华为平板-POS1 2@2x.png'),
      },
    },
  },
  {
    key: 'dataMap',
    name: '首頁-產品banner',
    img: require('../../assets/do-img/首頁-產品banner.png'),
    show: true,
    configs: {
      title: '降低人力成本 提高翻檯率 一部電話輕鬆管理餐廳',
      desc: '支持多終端設備落單幫你化繁為簡',
      img: require('../../assets/material/首頁/yuntongbu-img.png'),
      alt: '',
      bgImg: require('../../assets/material/首頁/yuntongbu-bj.png'),
    },
  },
  {
    key: 'tryToContact',
    name: '服務專家',
    img: require('../../assets/do-img/服務專家.png'),
    show: true,
    configs: {
      title: '智能餐飲服務專家，全方位保障餐廳營運的成功！',
      desc: '科技券計畫由創新暑推出，以政府資助的方式，協助中小企提高生產力或轉型。Gingersoft提供餐飲科技顧問\\n服務，為你開發餐飲管理系統，更可助你獲取政府資助金額高達60萬。',
      descAlign: 'left',
      btns: [
        {
          title: '預約演示',
        },
        {
          title: '聯繫我們',
        },
      ],
    },
  },
  {
    key: 'topWhatsApp',
    name: 'Top WhatsApp',
    img: '../../assets/material/首頁/fanhui.png',
    show: false,
    configs: {
      src: require('../../assets/material/首頁/fanhui.png'),
      fit: 'contain',
      go: '',
    },
  },
  {
    key: 'image',
    name: '長圖',
    img: 'https://ricepon.oss-cn-hongkong.aliyuncs.com//home/longImage.png',
    show: true,
    configs: {
      src: 'https://ricepon.oss-cn-hongkong.aliyuncs.com//home/longImage.png',
      phoneSrc:
        'https://ricepon.oss-cn-hongkong.aliyuncs.com//home/longImage.png',
      fit: 'contain',
      go: '',
    },
  },
  {
    key: 'topBannar',
    name: '其他-頂部banner',
    img: require('../../assets/do-img/其他-頂部banner.png'),
    show: true,
    configs: {
      src: require('../../assets/material/產品/chanpin-banner.png'),
      alt: '',
      title: '標題內容可修改',
    },
  },
  {
    key: 'dataList',
    name: '首頁-我們的優勢',
    img: require('../../assets/do-img/首頁-我們的優勢.png'),
    show: true,
    configs: {
      title: '標題內容可修改',
      desc: '詳情內容可修改',
      items: [
        {
          icon: require('../../assets/material/報價/anzhang.png'),
          slider: 0,
          title: '標題內容可修改',
          desc: '詳情內容可修改',
        },
      ],
    },
  },

  {
    key: 'pictureAndText',
    name: '圖文並茂',
    img: '',
    show: false,
    configs: {
      bgSrc: '',
      title: '',
      desc: '',
      img: '',
      alt: '',
    },
  },
  {
    key: 'cardAndText',
    name: 'why not us',
    img: require('../../assets/do-img/why not us.png'),
    show: true,
    configs: {
      title: 'WHY NOT US',
      desc: '你的智能餐飲管理專家',
      items: [
        {
          icon: require('../../assets/icon/多项功能.png'),
          title: '雲端餐飲管理',
          desc: '自定菜單 | 折扣管理 | 數據整合',
          bgSrc: require('../../assets/material/首頁/xiaolv-moren.png'),
        },
      ],
    },
  },
  {
    key: 'devHistory',
    name: '發展歷程',
    img: '',
    show: false,
    configs: {
      items: [
        {
          yyyy: '2022年',
          desc: '現在',
        },
        {
          yyyy: '2021年',
          desc: '雲端餐飲管理系統RicePOS與會計系統 Xero完成技術對接業務拓展至新加坡',
        },
        {
          yyyy: '2020年',
          desc: '與著名物流系統、電子支付系統完成技術對接',
        },
        {
          yyyy: '2027年',
          desc: '全港首創固定碼點餐，建立會員系統 推出飲食著數APP“飯糰”',
        },
        {
          yyyy: '2013年',
          desc: '我們擁有了第一個合作夥伴',
        },
        {
          yyyy: '2012年',
          desc: '香港成立 Gingersoft',
        },
      ],
    },
  },
  {
    key: 'ourClients',
    name: '首頁-我們的客戶',
    img: require('../../assets/do-img/首頁-我們的客戶.png'),
    show: true,
    configs: {
      autoplay: true, // 是否自動輪播
      interval: 3, // 時間
      title: '我們的客戶',
      desc: '全面提高餐廳營運效率',
      items: [
        {
          img: require('../../assets/material/首頁/womenkehu-1.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/首頁/womenkehu-2.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/首頁/womenkehu-3.png'),
          alt: '',
        },
        {
          img: require('../../assets/material/首頁/womenkehu-4.png'),
          alt: '',
        },
      ],
    },
  },
  {
    key: 'canDoing',
    img: require('../../assets/do-img/預約演示.png'),
    name: '產品預約演示',
    show: true,
    configs: {
      topPadding: 0,
      padding: 0,
      nPadding: 78,
      image: '',
      phoneImage: '',
      title: 'GSPOS餐飲管理系統',
      subTitle: {
        text: '解決餐廳常遇到的問題',
        color: '#145F9D',
      },
      btn1: {
        title: '預約演示',
        go: '',
      },
      btn2: {
        title: '聯絡我們',
        go: '',
      },
      arr: [{ title: '基本點餐功能' }, { title: '後台管理功能' }],
    },
  },
  {
    key: 'textRicePOS',
    img: require('../../assets/testImg/RicePOSMain.png'),
    name: 'RicePOS-圖文2',
    show: true,
    configs: {
      title: 'RicePOS',
      // titleLineHeight: 25,
      // descLineHeight: 25,
      descMarginTop: 15,
      // itemsLineHeight: 25,
      itemsMarginTop: 10,
      itemMarginTop: 8,
      marginLeft: parseInt(win_width * 0.038),
      paddingTop: parseInt(win_width * 0.038),
      paddingRight: parseInt(win_width * 0.142),
      paddingBottom: parseInt(win_width * 0.038),
      paddingLeft: parseInt(win_width * 0.187),
      desc: '雲端餐飲管理系統隨時隨地助你輕鬆營運',
      src: require('../../assets/testImg/RicePOSIphone.png'), //好像不要
      alt: '',
      image: require('../../assets/testImg/RicePOSIphone.png'),
      bgImg: require('../../assets/testImg/bgImgricePOS.png'),
      items: [
        {
          title: '【落單埋單一機過】',
          desc: '用個APP完成從落單到打折到埋單，大大節省人力成本，提高餐廳翻檀率',
        },
        {
          title: '【餐飲難題一機解決】',
          desc: '做住堂食又可以接外賣，疫情時代兩種模式缺一不可',
        },
        {
          title: '【數據分析一機睇曬】',
          desc: '多種報表管理及分析，為你展示營運實況，助你更容易制定營運策略',
        },
        {
          title: '【餐飲難題一機解決】',
          desc: '適用於小型、中型及大型連鎖餐廳，無論鯀中西意日韓，總有一套解幫到你',
        },
      ],
    },
  },
  {
    key: 'RicePOSImageText4',
    name: 'RicePOS_圖文4',
    img: require('../../assets/testImg/main1.png'),
    show: true,
    configs: {
      title: 'RicePOS',
      desc: '移動端落單，令你的生意運作更easy！',
      image: require('../../assets/testImg/main2.png'),
      alt: '',
      paddingTop: parseInt(win_width * 0.061),
      paddingRight: parseInt(win_width * 0.175),
      paddingBottom: parseInt(win_width * 0.079),
      paddingLeft: parseInt(win_width * 0.175),
      backgroundColor: 'rgb(255,255,255)',
      startBGC: 'rgb(190, 28, 66)',
      type: 1,
      items: [
        {
          title: '餐檯',
          icon: require('../../assets/testImg/icon1.png'),
        },
        {
          title: '落單',
          icon: require('../../assets/testImg/icon2.png'),
        },
        {
          title: '賬單',
          icon: require('../../assets/testImg/icon3.png'),
        },
        {
          title: '會員',
          icon: require('../../assets/testImg/icon4.png'),
        },
        {
          title: '會員',
          icon: require('../../assets/testImg/icon5.png'),
        },
        {
          title: '更多功能',
          icon: require('../../assets/testImg/icon6.png'),
        },
      ],
    },
  },
  {
    key: 'RicePOSOrder',
    name: 'RicePOSImageText3',
    img: require('../../assets/testImg/RicePOS-Main.png'),
    show: true,
    configs: {
      title: 'RicePOS',
      desc: '完善、強大的餐廳管理工具，快速完成點餐、結賬',
      iconTitle: '無論係中西意日韓，咖啡店定小食店，都可以適合嘅管理方案',
      image: require('../../assets/testImg/RicePOS-order.png'),
      alt: '',
      marginLeft: 75,
      paddingTop: parseInt(win_width * 0.038),
      paddingRight: parseInt(win_width * 0.14),
      paddingBottom: parseInt(win_width * 0.038),
      paddingLeft: parseInt(win_width * 0.187),
      backgroundColor: 'rgb(255,255,255)',
      textAlineArr: [
        {
          value: 'left',
          label: '居左',
        },
        {
          value: 'right',
          label: '居右',
        },
        {
          value: 'center',
          label: '居中',
        },
      ],
      textAline: 'center',
      items: [
        {
          title: '中餐',
          icon: require('../../assets/testImg/lunchIcon.png'),
        },
        {
          title: '火鍋',
          icon: require('../../assets/testImg/hotPotIcon.png'),
        },
        {
          title: '小食',
          icon: require('../../assets/testImg/snackIcon.png'),
        },
        {
          title: '西餐',
          icon: require('../../assets/testImg/westernFoodIcon.png'),
        },
        {
          title: '咖啡',
          icon: require('../../assets/testImg/coffeeIcon.png'),
        },
        {
          title: '米線/拉麵',
          icon: require('../../assets/testImg/ramenNoodlesIcon.png'),
        },
      ],
    },
  },
  {
    key: 'deliverySystemImageText',
    img: require('../../assets/testImg/deliverySystemImageText.png'),
    name: '外賣系統_圖文1',
    show: true,
    configs: {
      title: '餐廳面臨的經營問題',
      desc: '外賣管理系統令你運營賺到盡！',
      text: '收益無法評估',
      paddingTop: parseInt(win_width * 0.038),
      paddingRight: parseInt(win_width * 0.142),
      paddingBottom: parseInt(win_width * 0.038),
      paddingLeft: parseInt(win_width * 0.187),
      backgroundColor: 'rgb(190,28,66)',
      items: [
        {
          title: '用工難\\n人力成本高',
          icon: require('../../assets/testImg/Vector.png'),
        },
        {
          title: '基礎營業\\n數據缺失',
          icon: require('../../assets/testImg/Vector.png'),
        },
        {
          title: '食材、經營\\n成本攀升',
          icon: require('../../assets/testImg/Vector.png'),
        },
      ],
    },
  },
  {
    key: 'qrCodeGraphicText2',
    img: require('../../assets/do-img/QR Code點餐_圖文2.png'),
    name: 'QR Code點餐_圖文2',
    show: true,
    configs: {
      // 標題
      title: '餐廳QR Code點餐方案',
      // 副標題
      subTitle: '縮減用戶等待時間 提升用戶消費體驗！',
      // 底色
      bgColor: '#FFFFFF',
      desc: '',
      descAligin: 'left',
      // 環繞 子體
      items: [
        {
          // 文字
          text: '排隊取號',
          // 底色
          bgColor: '#BE1C42',
        },
        {
          // 文字
          text: 'QR Code 點餐單',
          // 底色
          bgColor: '#BE1C42',
        },
        {
          // 文字
          text: '進店',
          // 底色
          bgColor: '#BE1C42',
        },
        {
          // 文字
          text: '餐牌',
          // 底色
          bgColor: '#BE1C42',
        },
      ],
      // 類型
      type: '0',
      centerTitle: '應用場景化',
    },
  },
  {
    key: 'memberSystemGraphicText2',
    img: require('../../assets/do-img/會員系統_圖文2.png'),
    name: '會員系統_圖文2',
    show: true,
    configs: {
      // 標題
      title: '會員系統',
      // 副標題
      subTitle: '一站式會員系統，全年優惠不斷',
      // 環繞 子體
      items: [
        {
          // 文字
          text: '多種支付方式',
          // 底色
          bgColor: ['rgba(35, 116, 255, 1)', 'rgba(61, 129, 255, 1)'],
          //
          icon: '',
        },
        {
          // 文字
          text: '積分',
          // 底色
          bgColor: ['rgba(35, 116, 255, 1)', 'rgba(61, 129, 255, 1)'],
          //
          icon: '',
        },
        {
          // 文字
          text: '優惠券',
          // 底色
          bgColor: ['rgba(3, 194, 255, 1)', 'rgba(14, 209, 254, 1)'],
          //
          icon: '',
        },
        {
          // 文字
          text: '預約點餐',
          // 底色
          bgColor: ['rgba(3, 194, 255, 1)', 'rgba(14, 209, 254, 1)'],
          //
          icon: '',
        },
        {
          // 文字
          text: '排隊取號',
          // 底色
          bgColor: ['rgba(1, 225, 180, 1)', 'rgba(1, 225, 180, 1)'],
          //
          icon: '',
        },
        {
          // 文字
          text: '外賣自取',
          // 底色
          bgColor: ['rgba(1, 225, 180, 1)', 'rgba(1, 225, 180, 1)'],
          //
          icon: '',
        },
      ],
      // 類型
      type: '0',
      centerTitle: '應用場景化',
      centerColor: 'rgba(190, 28, 66, 1)',
      centerIcon: '',
    },
  },
  {
    key: 'QRCodeOrderImageText1',
    img: require('../../assets/testImg/QRCodeOrder_imageText1.png'),
    name: 'QR Code點擊-圖文1',
    show: true,
    configs: {
      title: 'QR Code點餐',
      desc: '繁忙時間落單唔洗等 降低餐廳人力成本',
      paddingTop: parseInt(win_width * 0.04),
      paddingBottom: parseInt(win_width * 0.03),
      backgroundColor: 'rgb(255,255,255)',
      items: [
        {
          xAxisData: [
            {
              text: '提高效率',
              value: 12,
            },
            {
              text: '節省人力',
              value: 15,
            },
            {
              text: '實施管理數據',
              value: 21,
            },
            {
              text: '提升翻檯率',
              value: 28,
            },
            {
              text: '運營賺到盡',
              value: 38,
            },
          ],
          lineNumber: 5,
          // main2SymbolOffset: [-1, -30],
          main2SymbolRotate: 230,
        },
        {
          xAxisData: [
            {
              text: '吸引新客',
              value: 12,
            },
            {
              text: '新客留存',
              value: 22,
            },
            {
              text: '熟客活躍度',
              value: 12,
            },
            {
              text: '熟客流失挽回',
              value: 38,
            },
          ],
          lineNumber: 5,
          // main2SymbolOffset: [-1, -29],
          main2SymbolRotate: 260,
        },
      ],
    },
  },
  {
    key: 'qrCodeGraphicText4',
    img: require('../../assets/do-img/QR Code點餐_圖文4.png'),
    name: 'QR Code點餐_圖文4',
    show: true,
    configs: {
      type: '1',
      title: '幫你從經營到營銷到營利',
      subTitle: '',
      itemsBgL: 'rgba(255, 255, 255,1)',
      itemsBgR: 'rgba(248, 248, 248, 1)',
      textCenter: false,
      items: [
        {
          title: '堂食點餐',
          icon: '',
          content:
            '打造屬於你餐廳嘅服務方案，無論係日式定西式，係放題或fine dining，想QR Code點餐又得，想做埋外賣送餐又得。',
        },
        {
          title: '堂食點餐',
          icon: '',
          content:
            '打造屬於你餐廳嘅服務方案，無論係日式定西式，係放題或fine dining，想QR Code點餐又得，想做埋外賣送餐又得。',
        },
        {
          title: '堂食點餐',
          icon: '',
          content:
            '打造屬於你餐廳嘅服務方案，無論係日式定西式，係放題或fine dining，想QR Code點餐又得，想做埋外賣送餐又得。',
        },
      ],
    },
  },
  {
    key: 'marketingToolGraphicText1',
    img: require('../../assets/do-img/營銷工_圖文1.png'),
    name: '營銷工具圖文1',
    show: true,
    configs: {
      title: '營銷工具',
      subTitle: '令你的生意運作更easy！',
      status: 1,
      items: [
        {
          title: '超多著數，驚喜不斷',
          icon: '',
        },
        {
          title: '超多著數，驚喜不斷',
          icon: '',
        },
        {
          title: '超多著數，驚喜不斷',
          icon: '',
        },
        {
          title: '超多著數，驚喜不斷',
          icon: '',
        },
      ],
    },
  },
  {
    key: 'marketingToolGraphicText3',
    img: require('../../assets/do-img/營銷工_圖文3.png'),
    name: '營銷工具圖文3',
    show: true,
    configs: {
      title: '營銷工具',
      subTitle: '令你運營賺到盡！',
      centerText: '著數專區',
      items: [
        {
          title: '餐飲券',
          icon: '',
          content:
            '餐飲券是一種最有效鼓勵你的客人再次光顧或 吸引新顧客的方法，它可助你建立客戶 忠誠度以至提升你的營業額',
        },
        {
          title: '兌換方便快捷',
          icon: '',
          content:
            '食客預先購買優惠券/現金券， 去到餐廳show個QR Code就可以即時 享用優惠啦',
        },
        {
          title: '營業額翻倍',
          icon: '',
          content:
            '可選擇提供餐飲券或現金券折扣，刺激更多 食客消費，有效提升出席率',
        },
        {
          title: '電子方式更易存取',
          icon: '',
          content:
            '不同於紙質優惠券容易唔記得用或者 整污糟，只要有個QR Code，隨時都可以用， 過期又有提醒，好用又方便',
        },
      ],
    },
  },
  {
    key: 'gsPOSGraphicText1',
    img: require('../../assets/do-img/GSPOS_圖文1.png'),
    name: 'GSPOS_圖文1',
    show: true,
    configs: {
      title: '餐飲管理系統',
      subTitle: '讓餐廳經營更輕鬆更高效',
      image: require('../../assets/material/產品/GSPOS-设备1 2@2x.png'),
      bgImage: require('../../assets/material/產品/產品-GSPOS-圖1bj.png'),
      paddingTB: 200,
      paddingLR: 250,
      items: [
        {
          icon: require('../../assets/material/產品/點餐-icon.png'),
          title: '日常點餐',
        },
        {
          icon: require('../../assets/material/產品/管理-icon.png'),
          title: '後台管理',
        },
        {
          icon: require('../../assets/material/產品/時段管理-icon.png'),
          title: '時段管理',
        },
        {
          icon: require('../../assets/material/產品/會員管理-icon.png'),
          title: '會員管理',
        },
      ],
    },
  },
  {
    key: 'memberSystemGraphicText1',
    img: require('../../assets/testImg/會員系統-圖1.png'),
    name: '會員系統_圖文1',
    show: true,
    configs: {
      title: '會員系統',
      desc: '一站式會員系統，為你搜盡不同餐飲優惠',
      image: require('../../assets/testImg/會員系統-圖1-樣機.png'),
      paddingTop: parseInt(win_width * 0.056),
      paddingRight: parseInt(win_width * 0.171),
      paddingBottom: parseInt(win_width * 0.093),
      paddingLeft: parseInt(win_width * 0.171),
      backgroundColor: 'rgb(255,255,255)',
      items: [
        {
          icon: require('../../assets/testImg/icon_1.png'),
          title: '外賣外送',
        },
        {
          icon: require('../../assets/testImg/icon_2.png'),
          title: '自取模式',
        },
        {
          icon: require('../../assets/testImg/icon_3.png'),
          title: '預約點餐',
        },
        {
          icon: require('../../assets/testImg/icon_4.png'),
          title: '排隊取號',
        },
        {
          icon: require('../../assets/testImg/icon_5.png'),
          title: '手機點餐',
        },
        {
          icon: require('../../assets/testImg/icon_6.png'),
          title: '線上支付',
        },

        {
          icon: require('../../assets/testImg/icon_7.png'),
          title: '優惠券',
        },
        {
          icon: require('../../assets/testImg/icon_8.png'),
          title: 'QR Code點餐',
        },
        {
          icon: require('../../assets/testImg/icon_9.png'),
          title: '會員卡',
        },
      ],
      qrcodes: [
        {
          icon: require('../../assets/testImg/ios.png'),
          title: 'iOS 下載',
        },
        {
          icon: require('../../assets/testImg/android.png'),
          title: 'Android 下載',
        },
      ],
    },
  },
  {
    key: 'textSuspendedOnPicture',
    img: require('../../assets/testImg/textSuspendedOnPicture.png'),
    name: '首頁-圖文',
    show: true,
    configs: {
      title: '雲端餐飲系統',
      image: '',
      alt: '',
      subTitle:
        'Gingersoft薑軟件在2012年於香港成立，多年來一直專注研究及開發餐飲管理系統，致力為每位客戶提供客製化的智能餐飲管理方案。\n 雲端餐飲智能管理系統，全方位提供餐廳營運所需，一機操作方便快捷，雲端整合餐廳營運數據，推廣成效視覺化，外賣訂單模式，QR Code手機掃碼落單，會員及積分系統，務求為客戶節省人力成本、提高經營效率！',
    },
  },
  {
    key: 'frequentlyAskedQuestion',
    img: require('../../assets/testImg/frequentlyAskedQuestion.png'),
    name: '常見問題',
    show: true,
    configs: {
      title: '常見問題（FAQ）',
      subTitle: '幫助你快速了解更多餐飲POS',
      items: [
        {
          title: '點解餐廳要用雲端POS餐飲系統？',
          desc: '雲端POS餐飲系統幫助老闆可以實時管理餐廳，所有數據通過雲端即時傳送，就算人唔喺餐廳都可以及時處理問題！從落單到結賬都可以喺一個智能裝置完成，係每間餐廳從經營到營利嘅必備工具',
        },
        {
          title: '餐廳用QR CODE手機落單有咩好？',
          desc: '餐飲業最常面對嘅問題就係：租金高、人工高、食材成本高及利潤低。為咗可以開源節流，使用QR Code手機落單可以節省人手成本，提高翻檯率，有助餐廳增加收入',
        },
      ],
    },
  },
  // banner輪播
  {
    key: 'bannarSwiper',
    img: require('@/assets/demo/bannarSwiper/bs_cover.png'),
    name: 'Bannar輪播',
    show: true,
    configs: {
      autoplay: false, // 是否自動輪播
      interval: 3, // 時間
      loop: false, // 是否 循環'
      effect: 'slide',
      item: [
        {
          // 標題
          title: 'RicePOS支持不同設備雲同步',
          // 描述
          desc: '移動式多功能餐飲管理系統，搭建優良高效管理系統。 輕鬆管理所有方便快捷點餐服務，減低落錯單機會。 數據可視化，直觀看到數據變化趨勢。',
          // 展示類型 left:文字在左, right:文字在右
          position: 'left',
          // 按鈕組
          btns: [
            {
              //風格, 線性 plain 或是 實體 default
              style: 'default',
              title: '預約示範',
            },
            {
              //風格, 線性 plain 或是 實體 default
              style: 'plain',
              title: '查看詳情',
            },
          ],
          // 圖片
          img: {
            url: require('../../assets/demo/bannarSwiper/bs_img.png'),
            alt: '',
          },
          // 背景
          bg: require('../../assets/demo/bannarSwiper/bs_bg.png'),
        },
      ],
    },
  },

  {
    key: 'suspensionModule',
    img: require('../../assets/demo/suspensionModule/sm_cover.png'),
    name: '懸浮模塊',
    show: true,
    configs: {
      items: [
        {
          img: require('../../assets/demo/suspensionModule/sm_1.png'),
          number: '10',
          desc: '年+',
          content: '從2010年至今',
          time: 2000,
        },
        {
          img: require('../../assets/demo/suspensionModule/sm_2.png'),
          number: '1500',
          desc: '+',
          content: '服務餐廳數',
          time: 2000,
        },
        {
          img: require('../../assets/demo/suspensionModule/sm_3.png'),
          number: '60',
          desc: '萬+',
          content: '產生訂單數',
          time: 2000,
        },
        {
          img: require('../../assets/demo/suspensionModule/sm_4.png'),
          number: '10000',
          desc: '億+',
          content: '完成交易額',
          time: 2000,
        },
        {
          img: require('../../assets/demo/suspensionModule/sm_5.png'),
          number: '100',
          desc: '萬+',
          content: '累計用戶數',
          time: 2000,
        },
      ],
    },
  },

  {
    key: 'nestSwiper',
    img: require('@/assets/demo/nestSwiper/ns_cover.png'),
    name: '嵌套輪播',
    show: true,
    configs: {
      bg: require('../../assets/demo/nestSwiper/ns_bg.png'),
      title: '明星產品陣容',
      desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
      items: [
        {
          icon: require('../../assets/demo/nestSwiper/ns_1.png'),
          bigIcon: require('../../assets/demo/nestSwiper/ns_big1.png'),
          title: '遙距排隊',
          desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
          imgs: [
            {
              img: require('../../assets/demo/nestSwiper/ns_img1.png'),
              alt: '',
            },
          ],
        },
      ],
    },
  },

  {
    key: 'textCardModule',
    img: require('../../assets/demo/textCardModule/tcm_cover.png'),
    name: '文字卡片模塊',
    show: true,
    configs: {
      bg: require('../../assets/demo/textCardModule/tcm_bg.png'),
      title: '主流餐飲平臺隨時使用',
      desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
      items: [
        {
          logo: require('../../assets/demo/textCardModule/tcm_1_logo.png'),
          title: 'FoodPanda',
          desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
          bg: require('../../assets/demo/textCardModule/tcm_1.png'),
        },
      ],
    },
  },

  {
    key: 'stackedLayerSwiper',
    img: require('../../assets/demo/stackedLayerSwiper/sls_cover.png'),
    name: '堆叠輪播',
    show: true,
    configs: {
      bg: '',
      bgColor: '#ECF4FF',
      title: '客戶評價',
      desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
      items: [
        {
          logo: require('../../assets/demo/stackedLayerSwiper/sls_logo.png'),
          name: '帝皇集團',
          user: '姜女士',
          text: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義文字描述就看到划的痕迹這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述',
        },
      ],
    },
  },

  {
    key: 'contactModule',
    img: require('../../assets/demo/contactModule/cm_cover.jpg'),
    name: '聯絡我們',
    show: true,
    configs: {
      title: '聯係我們',
      desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義',
      bgColor: '#292D4F',
      contact: {
        title: 'Gingersoft聯絡方式',
        types: [
          {
            logo: '',
            address: '',
          },
          {
            logo: '',
            address: '',
          },
        ],
        labels: [
          {
            label: '聯絡電話：',
            value: '8200 2022',
          },
          {
            label: '聯繫Email：',
            value: 'equiry@gingersoft.com.hk',
          },
          {
            label: '聯絡地址：',
            value: '九龍長沙灣用康街51-63號時穎中心1401-2室',
          },
        ],
        address: {
          mapX: '',
          mapY: '',
        },
      },
    },
  },
  // 蜂窝图
  {
    key: 'cellularModule',
    img: require('../../assets/demo/cellularModule/cem_cover.png'),
    name: '蜂窝模组',
    show: true,
    configs: {
      title: '合作夥伴',
      desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
      bg: require('../../assets/demo/cellularModule/cem_bg.png'),
      bgColor: '',
      gridRows: 4,
      gridColumns: 10,
      items: [
        {
          index: 0,
          img: require('../../assets/demo/cellularModule/cem_1.png'),
        },
      ],
    },
  },

  {
    key: 'payModule',
    img: require('../../assets/demo/payModule/pm_cover.png'),
    name: '蜂窝模组',
    show: true,
    configs: {
      title: '全線接入主流支付商',
      desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
      bg: require('../../assets/demo/payModule/pm_bg.png'),
      bgColor: '',
      items: [
        {
          logo: require('../../assets/demo/payModule/pm_icon.png'),
          name: '八達通',
        },
      ],
    },
  },

  {
    key: 'pricingModule',
    img: require('../../assets/do-img/報價-報價方案.png'),
    name: '報價-新',
    show: true,
    configs: {
      title: '全線接入主流支付商',
      desc: '這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是文字描述，可以自定義這裏是 文字描述，可以自定義',
      bg: '',
      bgColor: '',
      items: [
        {
          themeColor: 'rgb(51,143,255)',
          headImg: '',
          title: '免費版',
          desc: '適用於企業用戶體驗',
          price: '$ 0',
          priceDesc: '/年',
          disDesc: '支持 10 用戶',
          tag: {
            show: true,
            text: '推薦',
            bg: 'rgb(255,140,17)',
          },
          btn: {
            type: 'default',
            text: '免費領取',
            go: '',
          },
          child: [
            {
              label: '文件數量',
              value: '3個',
              type: 0,
              isTitle: false,
              delete: false,
            },
          ],
        },
      ],
    },
  },

  // * 全屏廣告
  {
    key: 'fullScreenAd',
    img: require('../../assets/do-img/全屏廣告.png'),
    name: '全屏廣告',
    show: true,
    configs: {
      setMealId: '',
      purchaseSpecId: '',
      // * 第一步
      first: {
        // * 背景
        bg: {
          // * 背景色 - 開始
          bgStart: '#fefaf2',
          bgStartByPhone: '#fefaf2',
          // * 背景色 - 結束
          bgEnd: '#fcefdd',
          bgEndByPhone: '#fcefdd',
          // * 圓角 - min
          minRadius: 'var(--font6)',
          minRadiusByPhone: 'var(--font6)',
          // * 圓角- max
          maxRadius: 'var(--font200)',
          maxRadiusByPhone: 'var(--font100)',
          // *
        },
        // * 標題
        title: {
          // * 標題文字
          text: 'Gingersoft限量大禮包',
          textByPhone: 'Gingersoft限量大禮包',
          // * 字體大小
          fontSize: 'var(--font30)',
          fontSizeByPhone: 'var(--font18)',
          // * 字體顏色
          fontColor: '#fff',
          fontColorByPhone: '#fff',
          // * 背景色 - 開始
          bgStart: '#fc6955',
          bgStartByPhone: '#fc6955',
          // * 背景色 - 結束
          bgEnd: '#ff5f98',
          bgEndByPhone: '#ff5f98',
          // * 投影底色
          shadowColor: '#f9e0c4',
          shadowColorByPhone: '#f9e0c4',
        },
        // * 描述
        desc: {
          // * 描述文字
          text: '12個月超長免費使用餐飲系統',
          textByPhone: '12個月超長免費使用餐飲系統',
          // * 字體大小
          fontSize: 'var(--font50)',
          fontSizeByPhone: 'var(--font24)',
          // * 字體顏色
          fontColor: '#35220b',
          fontColorByPhone: '#35220b',
          // * 投影底色
          shadowColor: '#f9e0c4',
          shadowColorByPhone: '#f9e0c4',
          fontHeight: 'var(--font70)',
          fontHeightByPhone: 'var(--font33)',
        },
        // * 最後一個
        other: {
          // * 描述文字
          text: '限量領取，先搶先得',
          textByPhone: '限量領取，先搶先得',
          // * 字體大小
          fontSize: 'var(--font26)',
          fontSizeByPhone: 'var(--font16)',
          // * 字體顏色
          fontColor: '#f19732',
          fontColorByPhone: '#f19732',
          // * 線條色 - 開始
          lineBgStart: 'rgba(241, 151, 50, 0.04)',
          lineBgStartByPhone: 'rgba(241, 151, 50, 0.04)',
          // * 線條色 - 結束
          lineBgEnd: '#f19732',
          lineBgEndByPhone: '#f19732',
        },
        // * 圖片
        img: {
          // * 圖片地址
          url: require('../../assets/default/Group 20841 1@2x.png'),
          urlByPhone: require('../../assets/default/Group 20841 1@2x.png'),
          // * 陰影顏色
          shadowBg: '#ffaea7',
          shadowBgByPhone: '#ffaea7',
        },
        // * 項目
        product: {
          // * 背景色
          bg: '#fcefdd',
          bgByPhone: '#fcefdd',
          // * 邊框色
          borderColor: '#ecc6a4',
          borderColorByPhone: '#ecc6a4',
          // * 字體大小
          fontSize: 'var(--font22)',
          fontSizeByPhone: 'var(--font14)',
          // * 字體顏色
          fontColor: '#ca915f',
          fontColorByPhone: '#ca915f',
          childs: [
            {
              // * 文字
              title: '取餐系統',
              titleByPhone: '取餐系統',
              // * 圖片
              icon: '',
              iconByPhone: '',
            },
          ],
        },
        // * 按鈕
        btn: {
          // * 文字
          text: '立即搶購',
          textByPhone: '立即搶購',
          // * 開始底色
          bgStart: '#ff9573',
          bgStartByPhone: '#ff9573',
          // * 結束底色
          bgEnd: '#f43f05',
          bgEndByPhone: '#f43f05',
          // * 字體大小
          fontSize: 'var(--font30)',
          fontSizeByPhone: 'var(--font18)',
          // * 字體顏色
          fontColor: '#fff4e6',
          fontColorByPhone: '#fff4e6',
        },
      },
      // * 第二步
      second: {
        // * 標題
        title: 'RicePOS，您的店鋪經營專家',
        titleByPhone: 'RicePOS，您的店鋪經營專家',
      },
      third: {
        // * 背景色
        bgColor: '#FFFFFF',
        bgColorByPhone: '#FFFFFF',
        //  * 頭部
        headBg: require('../../assets/default/19740d76343db91b3f2ccbd54324e880.png'),
        headBgByPhone: require('../../assets/default/19740d76343db91b3f2ccbd54324e880.png'),
        headIcon: require('../../assets/default/a426f0234d040327be280b9614892dbe.png'),
        headIconByPhone: require('../../assets/default/a426f0234d040327be280b9614892dbe.png'),
        // * 標題
        title: '恭喜您，獲得3個月免費體驗時長',
        titleByPhone: '恭喜您，獲得3個月免費體驗時長',
        titleFontSize: 'var(--font30)',
        titleFontSizeByPhone: '',
        titleFontColor: '#333333',
        titleFontColorByPhone: '#333333',
        // * 描述
        desc: '立即下載RicePOS應用程式開啟高效店鋪經驗致旅吧！',
        descByPhone: '立即下載RicePOS應用程式開啟高效店鋪經驗致旅吧！',
        descFontSize: 'var(--font18)',
        descFontSizeByPhone: '',
        descFontColor: '#333333',
        descFontColorByPhone: '#333333',
        btn: [
          {
            qrcode: '',
            icon: require('../../assets/default/c3dc634ba69d411225070873d7f76f87.png'),
            go: '',
          },
          {
            qrcode: '',
            icon: require('../../assets/default/4176c9cdb2dd399a09d0387db998bd8e.png'),
            go: '',
          },
        ],
      },
    },
  },
  // * q&a
  {
    key: 'questionsAndAnswers',
    img: require('../../assets/do-img/1d3ead00f9b09eaddb4de28b296810ae.png'),
    name: 'Q&A',
    show: true,
    configs: {
      items: [
        {
          question: {
            text: '問題標題',
            color: 'rgba(0, 76, 158, 1)',
            fontSize: 'var(--font30)',
            fontSizeByPhone: '',
            bgColor: 'rgba(207, 230, 255, 1)',
          },
          answer: {
            type: '1', // 1 : text; 2 : img ; 3 : table;
            text: {
              content: '這是內容',
            },
            img: {
              src: '',
            },
            table: {
              trs: [
                {
                  type: 'th',
                  bgColor: '',
                  child: [
                    {
                      bgColor: '',
                      color: '',
                      text: '',
                      width: '',
                      fontSize: '',
                    },
                  ],
                },
                {
                  type: 'td',
                  bgColor: '',
                  child: [
                    {
                      bgColor: '',
                      color: '',
                      text: '',
                      fontSize: '',
                      rowspan: 1,
                      textAlign: 'center',
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
      btn: {
        icon: '',
        bgColor: '#3b8eed',
        text: 'WhatsApp免費咨詢',
        color: '#fff',
        fontSize: 'var(--font22)',
        fontSizeByPhone: '',
        go: '',
      },
      whatsapp: {
        icon: '',
        go: '',
        bgColor: 'rgb(50,176,74)',
      },
    },
  },
];
/**
 * 獲取全部
 * @returns
 */
export const getConfig = () => {
  return config;
};

/**
 * 根據名稱獲取
 * @param {*} name
 * @returns
 */
export const getByName = (name) => {
  let item = {};
  for (let i in config) {
    if (config[i].key == name) {
      item = config[i];
      break;
    }
  }
  return item;
};
